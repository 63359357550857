import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Paltform({data}) {
    return (
        <div className="outcomes_section"
             style={{
                 backgroundImage: `linear-gradient(0deg, #ffffff 20%, ${checkData(data, 'bg_dark_color')}  20%)`
             }}
        >
            <Container>
                <Row>
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10} className="m-auto text-center offset-lg-1">
                        <h2 style={{color: `${checkData(data, 'txt_color')}`}}>{checkData(data, 'features_title')}</h2>
                        <div
                            style={{color: `${checkData(data, 'txt_color')}`}}> {HtmlParser(checkData(data, 'concept_description'))}</div>
                        <img
                            src={`${mediaBaseURL}${checkData(data, 'features_image')}`}
                            className="img-fluid"
                            alt={checkData(data, 'features_title')}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Paltform;